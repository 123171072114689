import React from 'react';
// import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ContactApp from './components/ContactApp';
import Navbar from './components/Navbar';


const App = () => {
  return (
    <div className='max-h-screen'>
      <Navbar />
      <HeroSection />
      <ContactApp />
     
     
    </div>
  );
};

export default App;