import React,{useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
  const [isDarkMode, setDarkMode] = useState(false);
 
  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(prefersDarkMode);
  }, []);


  const handleCallSave = () => {
    const phoneNumber1 = '+22892100130'; // Premier numéro de téléphone
    const phoneNumber2 = '+22899740219'; // Deuxième numéro de téléphone
    const facebookUrl = 'https://www.facebook.com/richessoham'; // Lien Facebook
    const githubUrl = 'https://github.com/essoham7'; //Lien GITHUB
    const twitterUrl = 'https://twitter.com/essoham7';// Lien TWITTER
    const linkedinUrl = 'https://www.linkedin.com/in/essoham-sibabi/'; // Lien LINKEDIN
    const address = 'Adidogome Yokoè, Lome'; // Adresse
    const latitude = 6.1766190; // Latitude de la position sur la carte
    const longitude = 1.1543460; // Longitude de la position sur la carte
  
    const contactData = [
      'BEGIN:VCARD',
      'VERSION:3.0',
      'N:SIBABI;Essoham;;;',
      'FN:Essoham SIBABI',
      `TEL;TYPE=CELL:${phoneNumber1}`,
      `TEL;TYPE=CELL:${phoneNumber2}`,
      `URL;TYPE=FACEBOOK:${facebookUrl}`,
      `URL;TYPE=GITHUB:${githubUrl}`,
      `URL;TYPE=TWITTER:${twitterUrl}`,
      `URL;TYPE=LINKEDIN:${linkedinUrl}`,
      `ADR;TYPE=HOME:;;${address};;;;;;`,
      `GEO:${latitude};${longitude}`,
      'END:VCARD',
    ];
  
    const vCardData = contactData.join('\n');
    const vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
    const vCardUrl = URL.createObjectURL(vCardBlob);
  
    // Crée un élément <a> pour le téléchargement du fichier
    const downloadLink = document.createElement('a');
    downloadLink.href = vCardUrl;
    downloadLink.download = 'essoham_sibabi.vcf';
  
    // Simule un clic sur le lien pour démarrer le téléchargement
    downloadLink.click();
  };
  
  

  return (

    <nav className='bg-gray-800' >
      <div className="container mx-auto px-4">
        <div className="flex relative items-center justify-between h-30 pt-2">

          <div onClick={handleCallSave} className={`z-30 md:flex ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full my-2 p-2`} >
            <div className='top-0 right-0 p-1' >
                <FontAwesomeIcon icon={faDownload} /> Save

            </div>
          </div>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
