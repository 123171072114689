import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGithub, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';




const ContactApp = () => {
  const [isDarkMode, setDarkMode] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(prefersDarkMode);
  }, []);


  const handleFacebookLink = () => {
    const facebookUrl = 'https://www.facebook.com/richessoham'; // URL Facebook à ouvrir

    // Vérifier le type d'appareil et le navigateur
    const userAgent = navigator.userAgent;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    if (isAndroid) {
      // Ouvrir l'application Facebook sur Android
      openApp(`fb://facewebmodal/f?href=${facebookUrl}`);
    } else if (isIOS) {
      // Ouvrir l'application Facebook sur iOS
      openApp(`fb://profile?id=${facebookUrl}`);
    } else {
      // Ouvrir dans le navigateur par défaut
      openApp(facebookUrl);
    }
  };

  const openApp = (url) => {
    window.open(url, '_blank');

    // Gestion de l'erreur d'ouverture de l'application
    const checkWindowClosed = setInterval(() => {
      if (window.closed) {
        clearInterval(checkWindowClosed);
        setError(true);
      }
    }, 500);
  };

  //GitHub
  const handleGitHubLink = () => {
    const githubUrl = 'https://github.com/essoham7'; // URL GitHub à ouvrir
    window.location.href = githubUrl;
  };
  
//Twiter

const handleTwitter = () => {
  const twitterUrl = 'https://twitter.com/essoham7'; // URL de votre compte Twitter
  window.open(twitterUrl, '_blank');
};

//Linkedin

const handleLinkedinLink = () => {
  const linkedinUrl = 'https://www.linkedin.com/in/essoham-sibabi/'; //URL de votre compte Linkedin
  window.open(linkedinUrl, '_blank');
}




  const handleCallTogocom = () => {
    const phoneNumber1 = '+22892100130'; // Premier numéro de téléphone
    const phoneNumber2 = '+22899740219'; // Deuxième numéro de téléphone
    const facebookUrl = 'https://www.facebook.com/richessoham'; // Lien Facebook
    const githubUrl = 'https://github.com/essoham7'; //Lien GITHUB
    const twitterUrl = 'https://twitter.com/essoham7';// Lien TWITTER
    const linkedinUrl = 'https://www.linkedin.com/in/essoham-sibabi/'; // Lien LINKEDIN
    const address = 'Adidogome Yokoè, Lome'; // Adresse
    const latitude = 6.1766190; // Latitude de la position sur la carte
    const longitude = 1.1543460; // Longitude de la position sur la carte
  
    const contactData = [
      'BEGIN:VCARD',
      'VERSION:3.0',
      'N:SIBABI;Essoham;;;',
      'FN:Essoham SIBABI',
      `TEL;TYPE=CELL:${phoneNumber1}`,
      `TEL;TYPE=CELL:${phoneNumber2}`,
      `URL;TYPE=FACEBOOK:${facebookUrl}`,
      `URL;TYPE=GITHUB:${githubUrl}`,
      `URL;TYPE=TWITTER:${twitterUrl}`,
      `URL;TYPE=LINKEDIN:${linkedinUrl}`,
      `ADR;TYPE=HOME:;;${address};;;;;;`,
      `GEO:${latitude};${longitude}`,
      'END:VCARD',
    ];
  
    const vCardData = contactData.join('\n');
    const vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
    const vCardUrl = URL.createObjectURL(vCardBlob);
  
    // Crée un élément <a> pour le téléchargement du fichier
    const downloadLink = document.createElement('a');
    downloadLink.href = vCardUrl;
    downloadLink.download = 'essoham_sibabi.vcf';
  
    // Simule un clic sur le lien pour démarrer le téléchargement
    downloadLink.click();
  };
  
  
  
  
  
  

  return (
  

<section className={` ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800' } rounded-t-lg shadow-lg py-5`}>
<div className="container mx-auto h-full items-center justify-center">
  <div className="grid grid-cols items-center gap-2 ">

  <div className={` ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full m-2 p-2`}>
       <div className='flex items-center '>
          <FontAwesomeIcon icon={faPhone} size="2x" className="mb-2 px-2 cursor-pointer" />
          <h2 className="text-xl font-bold mb-2 cursor-pointer" onClick={handleCallTogocom}>+228 92 1001 30 </h2>
       </div>
    </div>

    <div className={` ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full m-2 p-2`}>
       <div className='flex items-center '>
          <FontAwesomeIcon icon={faTwitterSquare} size="2x" className="mb-2 px-2" />
          <h2 className="text-xl font-bold mb-2" onClick={handleTwitter}>Twitter</h2>
       </div>
    </div>

  <div className={` ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full m-2 p-2`}>
       <div className='flex items-center '>
          <FontAwesomeIcon icon={faFacebook} size="2x" className="mb-2 px-2 " />
          <h2 className="text-xl font-bold mb-2 cursor-pointer" onClick={handleFacebookLink}>Facebook</h2>
       </div>
    </div>
       {error && (
          <div className="mt-4 text-red-500">
            Erreur lors de l'ouverture de l'application. Veuillez réessayer ou ouvrir le lien dans votre navigateur.
          </div>
        )}

    <div className={` ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full m-2 p-2`}>
       <div className='flex items-center '>
          <FontAwesomeIcon icon={faGithub} size="2x" className="mb-2 px-2" />
          <h2 className="text-xl font-bold mb-2 cursor-pointer" onClick={handleGitHubLink}>GitHub</h2>
       </div>
    </div>

    <div className={` ${isDarkMode?' bg-white':'bg-gray-800'} ${isDarkMode ? 'text-gray-800' : 'text-white'} rounded-full m-2 p-2`}>
       <div className='flex items-center '>
          <FontAwesomeIcon icon={faLinkedin} size="2x" className="mb-2 px-2" />
          <h2 className="text-xl font-bold mb-2 cursor-pointer" onClick={handleLinkedinLink}>Linkedin</h2>
       </div>
    </div>
    

  </div>
</div>
</section>
  );
};

export default ContactApp;