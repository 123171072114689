import React from 'react';
import Avatar from './Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEnvelope,  faGlobe, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const HeroSection = () => {

  //initier un echange WhatsApp
  const handleWhatsAppChat = () => {
    const phoneNumber = '+22892100130'; // Numéro de téléphone
  
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };
 // Envoie de mail

 const handleEmail = () => {
  const email = 'richessoham@gmail.com'; // Adresse e-mail

  const mailtoUrl = `mailto:${email}`;
  window.open(mailtoUrl, '_blank');
};


  //Initialiser un appel
  const handleCall = () => {
    const phoneNumber = '+22892100130'; // Numéro de téléphone à composer
    window.location.href = `tel:${phoneNumber}`;
  };
// website
  const handleWebSite = () => {
    const websiteUrl = 'https://esso-ai.pages.dev/'; // URL de votre site à ouvrir
    window.open(websiteUrl, '_blank');
};

//Sauvegarder les données
  // const handleDataSave = () => {
  //   const phoneNumber1 = '+22892100130'; // Premier numéro de téléphone
  //   const phoneNumber2 = '+22899740219'; // Deuxième numéro de téléphone
  //   const facebookUrl = 'https://www.facebook.com/richessoham'; // Lien Facebook
  //   const address = 'Adidogome Yokoè, Lome'; // Adresse
  //   const latitude = 6.1766190; // Latitude de la position sur la carte
  //   const longitude = 1.1543460; // Longitude de la position sur la carte
  //   const organization = 'LIDAO'; // Organisation
  //   const title = 'Software Developer'; // Titre
  //   const email = 'richessoham@gmail.com'; // Email
  //   const website ="https://esso-ai.pages.dev/";
  
  //   const contactData = [
  //     'BEGIN:VCARD',
  //     'VERSION:3.0',
  //     'N:SIBABI;Essoham;;;',
  //     'FN:Essoham SIBABI',
  //     `TEL;TYPE=CELL:${phoneNumber1}`,
  //     `TEL;TYPE=CELL:${phoneNumber2}`,
  //     `URL;TYPE=FACEBOOK:${facebookUrl}`,
  //     `ADR;TYPE=HOME:;;${address};;;;;;`,
  //     `GEO:${latitude};${longitude}`,
  //     `ORG:${organization}`,
  //     `TITLE:${title}`,
  //     `EMAIL:${email}`,
  //     `URL;TYPE=WEBSITE:${website}`,
  //     'END:VCARD',
  //   ];
  
  //   const vCardData = contactData.join('\n');
  //   const vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
  //   const vCardUrl = URL.createObjectURL(vCardBlob);
  
  //   // Crée un élément <a> pour le téléchargement du fichier
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = vCardUrl;
  //   downloadLink.download = 'contact.vcf';
  
  //   // Simule un clic sur le lien pour démarrer le téléchargement
  //   downloadLink.click();
  // };
  

  return (
    <section className="hero  text-gray-800 p-2"> 
      <div className="container mx-auto text-center">
        <div className="flex items-center justify-center absolute inset-x-0 top-0">
                 <Avatar  
                     imageUrl="https://res.cloudinary.com/dnhrnhrin/image/upload/c_thumb,w_200,g_face/v1685411780/Essoham_Sibabi_dtmdpy.jpg" 
                    alt="Avatar" 
                 />
        </div>
        <h1 className="text-2xl font-bold mb-2 pt-24 ">ESSOHAM SIBABI</h1>
        <p className="text-xl mb-2">Software Developer</p>
        <p className="text-xl mb-5 font-bold"> 
            <FontAwesomeIcon icon={faBuilding} />  
            LIDAO
          </p>
        <div className='flex flex-row justify-around'>
          <div className='flex flex-col items-center'>
              <button onClick={handleWhatsAppChat} className="bg-gray-800 hover:bg-gray-600 text-white font-bold h-12 w-12  rounded-full">
                <FontAwesomeIcon icon={faWhatsapp} size='2x' />  
              </button>
              <p>WhatsApp</p>
          </div>

          <div className='flex flex-col'>
              <button onClick={handleCall} className="bg-gray-800 hover:bg-gray-600 text-white font-bold  h-12 w-12 rounded-full">
                <FontAwesomeIcon icon={faPhone} size='2x'  />  
              </button>
              <p>Call</p>
          </div>

          <div className='flex flex-col'>
              <button onClick={handleEmail} className="bg-gray-800 hover:bg-gray-600 text-white font-bold h-12 w-12 rounded-full">
                <FontAwesomeIcon icon={faEnvelope} size='2x' />  
              </button>
              <p>Mail</p>
          </div>
          
          <div className='flex flex-col'>
              <button onClick={handleWebSite} className=" font-bold h-12 w-12 rounded-full ">
                <FontAwesomeIcon icon={faGlobe} size='2x' />  
              </button>
              <p>website</p>
          </div>
          
        </div>
      </div>
      
    </section>
  );
};

export default HeroSection;
