import React from 'react';

const Avatar = ({ imageUrl, alt }) => {
  return (
    <div className='h-40 w-40 pt-2'>
        <img
        src={imageUrl}
        alt={alt}
        className="rounded-full w-full h-full  object-cover"
        />
  </div>
  );
};

export default Avatar;
